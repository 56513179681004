import "./cards.css";
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify} from '@fortawesome/free-brands-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import ApplePodcasts from "../../assets/applepodcast.png";
import Fountain from "../../assets/fountain.jpg";
import Sphinx from "../../assets/sphinx.jpg";
import Spotify from "../../assets/spotify.jpg";
import Logo from "../../assets/Transparent Logo.png";
import MockData from "../../mock-data.json";
import Cason from "../../assets/RapidFire.jpg"

const card_ID = MockData.ID;
const user = MockData[0];

const Card = () => {

    console.log(card_ID)

    const buttons = ['Read', 'Listen', 'Watch']
    const [activeButtons, setActiveButtons] = useState([]);

    const handleButtonClick = (index) => {
        if(activeButtons.includes(index)){
            setActiveButtons(activeButtons.filter(i => i !== index))
        }
        else{
            setActiveButtons([...activeButtons, index])
        }
    }

    function generateLinks(data) {
        const platforms = ["Listen", "Read", "Watch"];
        const anchorLinks = [];
        
        platforms.forEach(platform => {
          const fiatPlatforms = data[platform]["Fiat_Platforms"];
          const bitcoinPlatforms = data[platform]["Bitcoin_Platforms"];
          
          for (const [key, value] of Object.entries(fiatPlatforms)) {
            anchorLinks.push(<a href={value} {...{[key]: value}}>{key}</a>);
          }
          
          for (const [key, value] of Object.entries(bitcoinPlatforms)) {
            anchorLinks.push(<a href={value} {...{[key]: value}}>{key}</a>);
          }
        });
        
        return anchorLinks;
      }

  return (

    <div className='CardsMain'>
        <div className='CardSingle'>
            <div className='UpperBar'>
                <div className='21Logo'>
                    <img 
                        src={Logo}
                        alt="21 Websites logo of a shield and lightning bolt"
                        className="CardLogo"
                    />
                </div>
                <div className="SpacerDiv">
                </div>
                <div>
                    <h3 className='AuthorName'>
                        {user.Full_Name}
                    </h3>
                </div>
                <div className="SpacerDiv">
                 
                </div>
            </div>
        <div className='CardBg'>
            <div className='CardInner'>
                <div className='Thumbnaildiv'>
                    {/* Need to import dummy data before using {$ThumbNail} */}
                    <img 
                                        // src={user.ThumbNail}
                                        src={Cason}
                                        alt={user.ThumbNailAltDescription}
                                        className="Thumbnailimage"
                                    />
                </div>
                <div className='ContentTitleDiv'>
                    <h4 className='ContentTitleText'>
                        {user.ContentTitle}
                    </h4>
                </div>
                <div className='ContentDescription'>
                    <p className="ContentParagraph">
                       {user.ContentParagraph}
                    </p>
                </div>
                <div className='ConetentBars'>
                    <div className='ReadUnderlying'>
                        <div className='FiatBar'>
                            {/* <a>Spotify</a>
                            <a>Podcasts</a> */}
                        </div>
                        <div className='BitcoinBar'>
                            {/* <a>Fountain</a>
                            <a>Sphinx</a>
                            <a>Breez</a> */}
                        </div>
                    </div>
                    <div className='ListenUnderlying'>
                    <div className='FiatBar'>
                        <div className="FiatItemsDiv">
                            <a className="FiatItems"><FontAwesomeIcon href="https://open.spotify.com/?" icon={faSpotify}/></a>
                            <a className="FiatItems"><FontAwesomeIcon href="https://www.apple.com/apple-podcasts/" icon={faPodcast}/></a>
                        </div>
                    </div>
                        <div className='BitcoinBar'>
                            <a className="BitcoinContentItems">
                                    <img 
                                        src={Fountain}
                                        alt="No action can be virtuous unless freely chosen quote"
                                        className="BitcoinContentItems"
                                    />
                            </a>
                            <a className="BitcoinContentItems">
                                    <img 
                                        src={Sphinx}
                                        alt="No action can be virtuous unless freely chosen quote"
                                        className="BitcoinContentItems"
                                    />
                            </a>
                            <a className="BitcoinContentItems">
                                    <img 
                                        src={Fountain}
                                        alt="No action can be virtuous unless freely chosen quote"
                                        className="BitcoinContentItems"
                                    />
                            </a>
                        </div>
                    </div>
                    <div className='WatchUnderlying'>
                        <div className='FiatBar'>
                            {/* <a>Spotify</a>
                            <a>Podcasts</a> */}
                        </div>
                        <div className='BitcoinBar'>
                            {/* <a>Fountain</a>
                            <a>Sphinx</a>
                            <a>Breez</a> */}
                        </div>
                    </div>
                </div>

                <div className='CardConsumeButtons' style={{ overflow: 'hidden'}}>
                    {buttons.map((name, index) => (
                        <button 
                            className={`${name}Button ${activeButtons.includes(index) ? 'active' : ''}`}
                            onClick={() => handleButtonClick(index)}
                            style={activeButtons.includes(index) ? {transform: "translateX(85%)", transition: 'transform 2s'} : {transition: 'transform 2s'}}
                        >
                            <div className="button-container">
                                <span>{name}</span>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
        <div className='LowerBar'>
            <div className='DonateContactButtons'>
                <button className='DonateButton'>DONATE</button>
                <button className='ContactButton'>CONTACT</button>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Card;